import * as React from "react";

import '../styles.scss';

const notFound = () => {
  return (
    <main>
      <h1>404 - No no </h1>
      <p>
        no.
      </p>
    </main>
  );
};
export default notFound;
